<!--
 * @Description:停车订单 订单补缴 lackOrderPay
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-09-07 17:32:13
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <van-nav-bar title="订单补缴"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <!-- 欠费信息 -->
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="title-text">欠费信息</div>
        <!-- <van-button class="title-button"
                    @click="handleClickCompletePay">用户已交费</van-button> -->
      </div>
      <div class="content-top">
        <div class="content-item">
          <div class="content-item-title">车牌号:</div>
          <div class="content-item-text">{{plateNumber}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">车牌颜色:</div>
          <div class="content-item-text">{{plateColor}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">共计欠费记录(条):</div>
          <div class="content-item-text">{{lackOrderList.order.length}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">共计欠费金额(元):</div>
          <div class="content-item-text">{{Number(lackOrderList.arrearsMoney/100).toFixed(2)}}</div>
        </div>
      </div>
    </div>
    <!-- 欠费记录 -->
    <div class="content-bottom">
      <div class="title">
        <div class="line"></div>
        <div class="title-text">欠费记录</div>
      </div>
      <div class="content-bottom-list">
        <van-checkbox-group ref="checkboxGroup"
                            v-model="checkboxGroupResult"
                            @change="checkboxGroupChange">
          <van-row class="list-item"
                   v-for="(item,index) in lackOrderList.order"
                   :key=index>
            <van-checkbox class="item-checkbox"
                          icon-size="17px"
                          :name="item"
                          shape="square">
              <van-row class="item-content">
                <span class="item-content-time">{{item.entranceTime}}</span>
                <span class="item-content-money">{{Number(Number(item.receivableMoneyAmount-item.receivedMoneyAmount)/100).toFixed(2)}}</span>
                <span class="item-content-right"
                      @click.stop="handleClickOrderDetails(item)"> ></span>
              </van-row>
            </van-checkbox>
          </van-row>
        </van-checkbox-group>
      </div>
    </div>
    <!-- 底部结算 -->
    <div class="bottom">
      <div class="bottom-content">
        <div class="bottom-content-left">
          <van-checkbox v-model="selectAllOrNotAll"
                        class="bottom-checkbox"
                        icon-size="17px"
                        shape="square"
                        @click="changeAll(selectAllOrNotAll)">
            {{selectAllOrNotAll? '全不选':'全选'}}
          </van-checkbox>
        </div>
        <div class="bottom-content-right">
          <span class="text-total">合计:</span>
          <span class="text-totalNumber">{{total}}</span>
          <span class="text-total">元</span>
        </div>
      </div>
      <div class="bottom-button">
        <van-button class="bottom-button-item"
                    @click="handleClickCloseAccount">结算</van-button>
      </div>
    </div>
    <!-- 收费二维码 -->
    <van-image-preview v-model="qrShow"
                       closeable
                       closeOnPopstate>
      <template v-slot:index>
        <van-row class="qr">
          <vue-qr :correctLevel="3"
                  :autoColor="false"
                  :text="codeUrl"
                  :logoSrc="icon + '?cache'"
                  :logoScale="0.2"
                  :size="200">
          </vue-qr>
        </van-row>
        <div style="text-align: center">支付金额：{{total}}</div>
      </template>
    </van-image-preview>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import VueQr from 'vue-qr'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { VueQr },
  data () {
    // 这里存放数据
    return {
      plateNumber: '',
      plateColor: '',
      lackOrderList: {
        order: []
      },
      total: 0,
      selectAllOrNotAll: '',
      checkboxGroupResult: [],
      orderIds: [],
      qrShow: false,
      codeUrl: '',
      icon: require('@/assets/parkingOrder/weixin.png')
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.plateNumber = this.$route.query.plateNumber
    this.plateColor = this.$route.query.plateColor
    this.queryLackOrder()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 用户已交费
    // handleClickCompletePay () {
    //   this.$router.go(-1)
    // },
    // 点击结算
    handleClickCloseAccount () {
      // this.$toast('暂不能合单缴纳，请点击单个详情查看订单缴纳')
      // 合单支付
      if (Number(this.total) === 0) {
        this.$toast('请先选择！')
      } else {
        const info = {
          openid: null,
          orderIds: this.orderIds,
          description: '微信支付'
        }
        this.$parkingOrder.nativePayBatch(info).then(response => {
          this.$toast.clear()
          if (response.resultEntity) {
            this.codeUrl = response.resultEntity
            this.qrShow = true
          }
        })
      }
    },
    // 查看欠费订单详情
    handleClickOrderDetails (item) {
      this.queryXinyong(item)
    },
    // 获取车辆信用
    queryXinyong (item) {
      const info = {
        plateNumber: item.plateNumber,
        numberPlateColorCode: item.numberPlateColorCode
      }
      this.$parkingOrder.getArrearsOrderInfoByPlateNumberAndCode(info).then(res => {
        // 车辆无欠费订单
        if (res.resultEntity.arrearsMoney === 0) {
          item.xinyongText = '该车信用良好无欠费'
          item.xinyongCode = 1
          item.xinyongLength = 0
        } else {
          if (item.orderStatusCode === 2 && res.resultEntity.order.length >= 1) {
            // 已交费车辆有欠费记录
            item.xinyongText = '欠费车辆,请追缴停车费'
            item.xinyongCode = -1
            item.xinyongLength = 1
          } else if (item.orderStatusCode === 1 && res.resultEntity.order.length >= 1) {
            // 未交费车辆有欠费记录
            item.xinyongText = '欠费车辆,请追缴停车费'
            item.xinyongCode = -1
            item.xinyongLength = 1
          } else if (item.orderStatusCode === -1 && res.resultEntity.order.length > 1) {
            // 欠费车辆有多条欠费记录
            item.xinyongText = '欠费车辆,请追缴停车费'
            item.xinyongCode = -1
            item.xinyongLength = 1
          } else if (item.orderStatusCode === -1 && res.resultEntity.order.length === 1) {
            // 欠费车辆有一条欠费记录
            item.xinyongText = '欠费车辆,请缴纳停车费'
            item.xinyongCode = -1
            item.xinyongLength = 0
          }
        }
        this.$router.push({
          name: 'parkOrderDetails',
          query: { orderItem: JSON.stringify(item) }
        })
      })
    },
    // 全选或全不选
    changeAll (state) {
      this.$refs.checkboxGroup.toggleAll(state)
    },
    // 多选组发生改变
    checkboxGroupChange (names) {
      this.orderIds = []
      if (names.length < this.lackOrderList.order.length) {
        this.selectAllOrNotAll = false
      } else {
        this.selectAllOrNotAll = true
      }
      let total = 0
      names.forEach(item => {
        total += Number(Number(item.receivableMoneyAmount) -
          Number(item.receivedMoneyAmount))
        this.orderIds.push(item.orderSequence)
      })
      this.total = Number(Number(total) / 100).toFixed(2)
    },
    // 查询欠费订单
    queryLackOrder () {
      const info = {
        plateNumber: this.$route.query.plateNumber,
        numberPlateColorCode: this.$route.query.numberPlateColorCode
      }
      this.$parkingOrder.getArrearsOrderInfoByPlateNumberAndCode(info).then(res => {
        this.lackOrderList = res.resultEntity
      })
    },
    // 返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 92%;
    margin-top: 10px;
    margin-left: 4%;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(221, 221, 221, 0.16);
    opacity: 1;
    border-radius: 4px;
    .title {
      width: 100%;
      height: 39px;
      border-bottom: 1px dashed #dddddd;
      display: flex;
      align-items: center;
      position: relative;
      .line {
        width: 2px;
        height: 18px;
        background: #0aa0f4;
        opacity: 1;
        border-radius: 6px;
        margin-left: 3%;
      }
      .title-text {
        width: 50%;
        margin-left: 2%;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 0px;
        color: #333333;
        opacity: 1;
      }
      // .title-button {
      //   width: 42%;
      //   height: 30px;
      //   border-radius: 20px;
      //   font-size: 14px;
      //   font-family: PingFang SC;
      //   font-weight: 400;
      //   color: #ffffff;
      //   background: #19a9fc;
      //   opacity: 1;
      // }
    }
    .content-top {
      width: calc(100% - 5% -2px);
      padding-left: calc(5% + 2px);
    }
    .content-item {
      width: 100%;
      height: 33px;
      line-height: 33px;
      display: flex;
      align-items: center;
      .content-item-title {
        width: 120px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        opacity: 1;
      }
      .content-item-text {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        opacity: 1;
      }
    }
  }
  .content-bottom {
    width: 92%;
    height: calc(100% - 120px - 86px - 30px - 172px);
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 4%;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(221, 221, 221, 0.16);
    opacity: 1;
    .title {
      width: 100%;
      height: 39px;
      border-bottom: 1px dashed #dddddd;
      display: flex;
      align-items: center;
      position: relative;
      .line {
        width: 2px;
        height: 18px;
        background: #0aa0f4;
        opacity: 1;
        border-radius: 6px;
        margin-left: 3%;
      }
      .title-text {
        width: 50%;
        margin-left: 2%;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 0px;
        color: #333333;
        opacity: 1;
      }
    }
    .content-bottom-list {
      width: 100%;
      height: calc(100% - 40px);
      overflow-x: hidden;
      overflow-y: auto;
      .list-item {
        width: 100%;
        height: 44px;
        line-height: 44px;
        border-bottom: 1px solid #dddddd;
        .item-checkbox {
          width: calc(100% - 3%);
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 3%;
          position: relative;
          .item-content {
            .item-content-time {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
              opacity: 1;
            }
            .item-content-money {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #ff6c76;
              opacity: 1;
              position: absolute;
              right: 30px;
            }
            .item-content-right {
              position: absolute;
              right: 10px;
              font-size: 20px;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 120px;
    background: #ffffff;
    opacity: 1;
    box-shadow: 0px 3px 6px rgba(221, 221, 221, 0.16);
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    .bottom-content {
      width: 100%;
      height: 70px;
      line-height: 70px;
      display: flex;
      align-items: center;
      position: relative;
      .bottom-content-left {
        .bottom-checkbox {
          margin-left: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          opacity: 1;
        }
      }
      .bottom-content-right {
        position: absolute;
        right: 20px;
        .text-total {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          opacity: 1;
        }
        .text-totalNumber {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ff6c76;
          opacity: 1;
        }
      }
    }
    .bottom-button {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .bottom-button-item {
        width: 66%;
        height: 40px;
        border-radius: 20px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        background: #19a9fc;
        opacity: 1;
      }
    }
  }
  .qr {
    margin-top: 200px;
  }
}
</style>
